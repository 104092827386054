'use-strict';

export var querySelect = function (selector) {
    return document.querySelector(selector);
};

export var queryAll = function (selector) {
    return document.querySelectorAll(selector);
};

/*
* Event delegation
*/
export var delegate = function (element, eventName, selector, fn, eventoptions) {
    if (element === null) {
        console.warn('Element not found', {el: element});
        return;
    }

    var options = eventoptions || {};
    element.addEventListener(eventName, function(event) {
        var possibleTargets = element.querySelectorAll(selector);
        var target = event.target;

        for (var i = 0, l = possibleTargets.length; i < l; i++) {
            var el = target;
            var p = possibleTargets[i];

            while(el && el !== element) {
                if (el === p) {
                    return fn.call(p, event);
                }

                el = el.parentNode;
            }
        }
    }, options);
};

// Get the selected fields from a multiselect
export const getSelectedFromNodeList = (nodeList) => {
    const arr = [];
    for (let i = nodeList.length; i--; arr.unshift(nodeList[i])); // create array

    return arr.filter(function (option) {
        return (option.value !== '' && option.selected === true) ? true : false;
    });
};

// update the filterlist from the multiselect
export const updateFilterListFromSelect = (selectValues, filterElement) => {
    // Create a new 'change' event
    const event = new Event('change');

    const filterInputs = filterElement.querySelectorAll('input');
    for (let i = 0; i < filterInputs.length; i++) {
        const inputEl = filterInputs[i];
        for (let p = 0; p < selectValues.length; p++) {
            // if the current input from the filterlist has the same value as an selected option, check it
            if (inputEl.value === selectValues[p].value) {
                inputEl.checked = true;

                // Dispatch change event
                inputEl.dispatchEvent(event);
                break;
            } else {
                inputEl.checked = false;
            }
        }
    }
};