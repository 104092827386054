'use-strict';

/* global TweenLite Sine */

import { MDCTemporaryDrawer } from '@material/drawer';
import { querySelect, queryAll } from './helpers/dom';
import { initPhotoSwipe } from './components/lightbox';

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                this.parentNode.removeChild(this);
            }
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

const desktopMedia = window.matchMedia('(min-width: 992px)');

// Handle Desktop Navigation
const menu = querySelect('.show-menu');
/*const sale = querySelect('.sale');*/
const headerBtn = querySelect('.btn-header');
const menuAndBtn = queryAll('.show-menu, .btn-header');
const menuBars = queryAll('.show-menu .menu-bar');
const navWrapper = querySelect('.nav-wrapper');
const mobileNav = querySelect('.mobile-nav');
const menuActiveClass = 'menu-desktop-active';
const menuMobileActiveClass = 'mdc-temporary-drawer--open';
const drawerElement = document.querySelector('.mdc-temporary-drawer');
const drawer = new MDCTemporaryDrawer(drawerElement);
const oldIE = (function () {
    if (document.documentElement.classList.contains('edge')) return false;
    const is10 = window.navigator.msPointerEnabled;
    const ie11 = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
    return (is10 === true || ie11 === true) ? true : false;
})();
let navAnim;

export default function main() {
    // Handle nav opening and closing
    menu.addEventListener('click', function (event) {
        // if in desktop view
        console.log(1);
        if (desktopMedia.matches) {
            // if menu is open, reverse everything
            if (navWrapper.classList.contains(menuActiveClass)) {
                navWrapper.setAttribute('aria-hidden', 'true');
                menu.classList.remove('transform-to-cross');

                // reverse header styling
                headerBtn.classList.remove('in-nav');
                TweenLite.set(menuBars, { clearProps: 'all' });

                // reverse nav and document
                navAnim.reverse();
                TweenLite.to(menuAndBtn, .3, { y: 0, ease: Sine.easeIn });
                TweenLite.to(hmf, .3, { y: 0, ease: Sine.easeIn });
                /*sale.classList.remove('active');*/
            } else {
                navWrapper.setAttribute('aria-hidden', 'false');
                menu.classList.add('transform-to-cross');
                showTopNav(navWrapper);
                /*sale.classList.add('active');*/
            }
        } else {
            menu.classList.add('transform-to-cross');
            drawer.open = true;
        }
    }, { passive: true });

    drawerElement.addEventListener('MDCTemporaryDrawer:close', function () {
        menu.classList.remove('transform-to-cross');
    });

    const hmf = queryAll('#header, main , #footer');
    const showTopNav = function (el) {
        // get burger menu coordinates
        const menuCord = menu.getBoundingClientRect();

        // Get the first position.
        const first = el.getBoundingClientRect();

        // Move it to the end.
        el.classList.add(menuActiveClass);

        // Get the last position.
        const last = el.getBoundingClientRect();

        // Invert.
        const invertx = menuCord.left - last.left;
        const inverty = menuCord.top - last.top;
        const invertsx = first.width / last.width;
        const invertsy = first.height / last.height;

        // set static stuff before animated
        TweenLite.set(menuBars, { background: '#fff' });
        headerBtn.classList.add('in-nav');

        // for IE only animate opacity (not so smooth for full animation)
        if (oldIE) {
            navAnim = TweenLite.fromTo(el, .3, {
                opacity: 0
            }, {
                opacity: 1,
                ease: Sine.easeOut,
                onReverseComplete() {
                    el.classList.remove(menuActiveClass);
                    TweenLite.set(el, { clearProps: 'all' });
                }
            });
        } else {
            // Go from the inverted position to last.
            navAnim = TweenLite.fromTo(el, .3, {
                x: invertx,
                y: inverty,
                scaleX: invertsx,
                scaleY: invertsy,
                transformOrigin: '0 0'
            }, {
                x: 0,
                y: 0,
                scaleX: 1,
                scaleY: 1,
                ease: Sine.easeOut,
                onReverseComplete() {
                    el.classList.remove(menuActiveClass);
                    TweenLite.set(el, { clearProps: 'all' });
                }
            });
        }

        TweenLite.to(menuAndBtn, .3, { y: last.height * -1 - 25, ease: Sine.easeOut });
        TweenLite.to(hmf, .3, { y: last.height, ease: Sine.easeOut });
    };

    //if (desktopMedia.matches) {
    const artists = queryAll('.artist');
    let observer = null;
    if (artists.length > 0) {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        observer = new IntersectionObserver(function (entries) {
            if (entries.length === 1) {
                const target = entries[0].target;
                console.info(target);
                observer.unobserve(target);
            }
        }, options);
    }

    for (let i = 0; i < artists.length; i++) {
        observer.observe(artists[i]);
        artists[i].addEventListener('click', function () {
            this.classList.toggle('slide-out');

            // extra class for text as it makes an anoying jump from the text height otherwise
            if (this.classList.contains('slide-out')) {
                setTimeout(() => this.classList.add('show-text'), 150);
            } else {
                this.classList.remove('show-text');
            }
        });
    }
    //}

    function removeDanger() {
        if (this.value.length) {
            let parent = this.parentElement;

            if (this.type === 'number' && parseInt(this.value) > 0) {
                parent.classList.remove('has-danger');
                this.removeEventListener('input', removeDanger);
                this.removeEventListener('change', removeDanger);
            } else if (this.type !== 'number') {
                parent.classList.remove('has-danger');
                this.removeEventListener('input', removeDanger);
                this.removeEventListener('change', removeDanger);
            }

            const errorFields = queryAll('.has-danger');
            if (errorFields.length === 0) {
                querySelect('.alert-danger').remove();
            }
        }
    }

    const errorFields = queryAll('.has-danger input, .has-danger textarea, .has-danger select');
    for (var ef = 0; ef < errorFields.length; ef++) {
        errorFields[ef].addEventListener('input', removeDanger);
        errorFields[ef].addEventListener('change', removeDanger);
    }
    // const scrollToTop = querySelect('.scroll-top');
    // if (scrollToTop !== null) {
    //     scrollToTop.addEventListener('click', function () {
    //         window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    //     }, {passive: true});
    // }

    initPhotoSwipe('.lightbox');
}